<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";
// import {
//   required,
//   email,
//   minLength,
//   sameAs,
//   maxLength,
//   minValue,
//   maxValue,
//   numeric,
//   url,
//   alphaNum,
// } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Pass achetés",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      buyModal:false,
      title: "Historique des Achats",
      tmpCoverFile: null,
      file: null,
      eventId: 0,
      fileModif: null,
      items: [
        {
          text: "Pass",
          href: "/",
        },
        {
          text: "Historique des achats",
          active: true,
        },
      ],
      type: 'text',
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codeParrainageFormModif: {
        id: 0,
        code: "",
        user: "",
        etat: "",
        nombrefilleul: 0,
      },
      parrainRow: null,
      totalRows: 1,
      currentPage: 1,
      currentPageActif: 1,
      currentPagePasse: 1,
      perPage: 100,
      perPagePasse: 100,
      perPageActif: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterActif: null,
      filterPast: null,
      codeevent: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "passName", sortable: true, label: "Pass" , style:"center"},
        { key: "type", sortable: true, label: "Type" },
        { key: "validity", sortable: true, label: "Valide pour" },
        { key: "amount", sortable: false, label: "Prix du Pass" },
        { key: "user", sortable: true, label: "Acheté par" },
        { key: "telephone", sortable: true, label: "Téléphone" },
        { key: "date", sortable: true, label: "Date de l'achat" },
      ],
      passForm: {
        dateLimite: "",
        heureLimite: "",
        amount: 0,
        stock: 0,
        remaining: 0,
        days: 0,
        description: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showActive: false,
      erreur: false,
      showRecharge: false,
      headerBgVariant: "warning",
      headerTextVariant: "info",
      bodyBgVariant: "light",
      showpopGainParrain: false,
      showpopGainMembre: false,
      gain_parrain_membre: {
        parrain: 0,
        membre: 0,
      },
      new_parrain: null,
      new_gain: null,
      startValue: 1,
      endValue: 500,
      parbon: 500,
      totalValueActif: 0,
      totalValuePasse: 0,
      userChargeLoader: false,
      newDataEvenement: [],
      soldTicket: [],
      recherche: "",
      eventInactifListe: [],
      soldTicketData: [],
      eventPasseListe: [],
      eventActifListeLength: 0,
      currentPassId: 0,
      showEditEvent: false,
      loadAttente: false,
      loadActif: false,
      loadPasse: false,
    };
  },
  validations: {},
  mounted() {
    //this.passActifList();
    // this.evenementInactifList();
    // this.evenementPasseList();
    this.init();
    //this.passId = this.$route.params.passId;
    //this.getSoldTicket();
    // setTimeout(() => {
    //   this.loadAttente = false
    //   this.loadActif = false
    //   this.loadPasse = false
    // }, 3000);
  },
  methods: {
    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank','noreferrer');
    },
    async init() {
      this.eventId = this.$route.params.eventId;
      //this.passActifList()
      this.getSoldTicket();
    },
   
//     async soldList() {
// const data = await apiRequest(
//     "GET",
//     'lots',
//     undefined,
//     false
// );
// //console.log("pass Id",this.eventId);
//
// if (data && data.data) {
//     const giftFormatTableActif = data.data.map((oneGift) => {
//         oneGift.sort == 0 ? this.type = "Perdant" : this.type = "Gagnant";
//         oneGift.active == true ? this.status = "Actif" : this.status = "Inactif";
//         return {
//             id: oneGift.id,
//             user: oneGift.libelle,
//             telephone: oneGift.telephone,
//             quantity: this.type,
//             total: this.status,
//             date: new Date(oneGift.dateCreation).toLocaleString("FR-fr")
//         }
//     });
//     this.giftToWinList = giftFormatTableActif;
//    
//     this.totalValueActif = this.giftToWinList.length;
// }
// },
    //staistiques Nombre total / cout global
    editRow(row) {
      this.showEditEvent = true;
      this.currentPassId = row.id;
      this.passForm.passName = row.passName;
      this.passForm.amount = row.amount;
      this.passForm.days = row.days;
      this.passForm.dateLimite = row.dateLimite.split(' ')[0];
      this.passForm.heureLimite = row.dateLimite.split(' ')[1]//.slice(0, 5);
      this.passForm.stock = row.stock;
    },
    onFilteredInactif(filteredItems) {
      this.totalValueInactif = filteredItems.length;
      this.currentPage = 1;
    },
    deactivateRow(row) {
      this.showdisable = true;
      this.currentPassId = row.id;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.currentPassId = row.id;
    },
    activeRow(row) {
      this.showActive = true;
      this.currentPassId = row.id;
    },
    closeModal() {
      this.showDelete = false;
    },
    // eslint-disable-next-line no-unused-vars
    async getSoldTicket() {
      const ticket = await apiRequest(
        "GET",
        "evenement/tikets-vendu"
       ,
       undefined, false
      );
      const data = ticket.data.billet;
      if (data) {
      const billetdata = data.map((oneSold)=>{
        return {
          id: oneSold.id,
          userId:oneSold.user.id,
          passid:oneSold.pass.id,
          user: oneSold.user.personne.firstname + " " + oneSold.user.personne.lastname,
          telephone: oneSold.user.personne.indicatifTel+" "+oneSold.user.personne.tel,
          date: new Date(oneSold.dateHeure).toLocaleString("fr-FR"),
          amount: oneSold.pass.montant,
          passName: oneSold.pass.pass,
          type:oneSold.pass.type,
          validity: oneSold.pass.nombreJours,
        }
      }).sort((a , b) => b.id - a.id);
      this.soldTicketData = billetdata;
      }
    },
    // setEventAdress: function (place) {
    //   if (place) {       
    //     this.passForm.adresseEvent = place.formatted_address;
    //     this.passForm.longitude = place.geometry.location.lng();
    //     this.passForm.latitude = place.geometry.location.lat();
    //   }
    // },
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="loadAttente == true || loadActif == true || loadPasse == true">
            <span class="loading">Chargement en cours... </span>
          </div>
          <div class="card-body">
            <!-- --><div class="row d-flex justify-content-between p-4">
              <!--<div class="col-md-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total acheté</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary" style="font-size:2em"> Total ici</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-4">
                <b-card header-class="bg-transparent border-primary" class="border border-info">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total restant</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary"  style="font-size:2em"> Total ici </h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-4">
                <b-card header-class="bg-transparent border-primary" class="border border-warning">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Coût total des achats</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary"  style="font-size:2em"> Total ici </h1>
                  </div>
                </b-card>
              </div>-->
              <!-- <div class="col-md-6">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <div>
                    <ul>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">PAID :</span>
                        Total paiement de réservation réussi
                      </li>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">Failed / ERROR :</span>
                        Solde insuffisant ou réservations rejetées ou numéro
                        invalide
                      </li>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">Initial :</span>
                        Total paiement de réservation initialisé
                      </li>
                    </ul>
                  </div>
                  <p>
                    Le statut
                    <span class="text-uppercase" style="color: black; font-weight: bold">Failed</span>
                    ne signifie pas de manière systématique que le paiement pour la réservation a
                    échoué. Cela voudrait simplement dire que la dernière valeur
                    enregistrée du statut suite à la vérification est
                    <span class="text-uppercase" style="color: black; font-weight: bold">failed</span>
                  </p>
                </b-card>
              </div> -->
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Historique des Achats</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterActif" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="soldTicketData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">
                      <template v-slot:cell(user)="row">
                        <div>
                          <div @click="goToUserDetail(row.item.userId)">
                        <span>
                        {{ row.item.user }}
                      </span>
                      <i  class="fas fa-eye"></i>
                      </div>
                        </div>
                      </template>
                      <template v-slot:cell(telephone)="row">
                        <div class="row d-flex justify-content-start">
                            <a :href="'https://wa.me/'+(row.item.telephone.replace('+','')).replace(' ','') " class="lien">{{ row.item.telephone }}</a>

                        </div>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a v-if="row.item.visible == true" href="javascript:void(0);" class="text-danger"
                          @click="deactivateRow(row.item)" v-b-tooltip.hover title="désactiver">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover @click="editRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="deleteRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-delete font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination v-model="currentPageActif" :total-rows="totalValueActif" :per-page="perPageActif"
                            :aria-controls="trajet"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
   <b-modal id="modal-backdrop" centered v-model="erreur" title="Veuillez remplir toutes les options."
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false">Ok</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce code de parrainage ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePass">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment supprimer cet évènement?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePass">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showActive" title="Voulez-vous vraiment activé cet évènement ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeevent()">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-center" centered size="xl" v-model="showEditEvent" title="Editer le Pass" title-class="font-18"
      hide-footer>
      <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="passName">Intitulé du Pass</label>
            <input :type="text" class="form-control py-4" id="passName" v-model="passForm.passName" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="amount">Prix du Pass</label>
            <input :type="type" class="form-control py-4" id="amount" v-model="passForm.amount" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="days">Validité du Pass</label>
            <input :type="type" class="form-control py-4" id="days" v-model="passForm.days" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="dateDebut">Heure de la date Limite</label>
            <input type="type" class="form-control py-4" id="heureLimite" onfocus="(this.type='time')"
              v-model="passForm.heureLimite" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="dateFin">Date Limite</label>
            <input class="form-control py-4" id="dateLimite" v-model="passForm.dateLimite" :type="type"
              onfocus="(this.type='date')" />
          </div>
        </div>
        <button class="btn btn-Back float-right" type="submit" @click="onUpdateEvent()">
          Modifier
        </button>
      </form>
    </b-modal>

    
  </Layout>
</template>
<style >
.alignBtn {
  display: flex;
  float: right;
  align-items: flex-end;
}
.bg-secondary-special {
  background: #3aaa35;
}
.bg-secondary-smile {
  background: #3aaa35;
}
.widget-wrapping {
  display: flex !important;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}
.lien{
    color:  #3AAA35 !important;
}
.thesize {
  font-size: 2em;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}
.typeParrain {
  margin-top: 0.444rem;
  font-size: 17px;
  color: #fff;
}
.gain {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}
.fa-edit {
  font-size: 17px;
  cursor: pointer;
}
thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}
.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}
.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}
ion-icon {
  cursor: pointer;
}
.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}
.containerDot {
  z-index: 100;
}
.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}
.paging_simple_numbers ul li button {
  color: #fff !important;
}
.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}
.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.pac-container {
  z-index: 10000 !important;
}
.btn-Back {
  background: #3aaa35;
  color: #fff;
}
.modal-header {
  background: #3aaa35;
}
.modal-title {
  color: #fff;
}
.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}
</style>
